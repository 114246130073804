import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import jwt_decode from "jwt-decode";

import { AuthService } from './auth/auth.service';
import { Item } from './item';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ItemService {

  itemServiceUrl: string = environment.apiUrl + '/items';

  constructor(private http: HttpClient,
              private authService : AuthService) { }

  getItems(): Observable<Item[]> {

    let token = this.authService.getToken();
    let decodedToken = jwt_decode(token);

    const headers= new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', token);

      console.log("token = " + token);
      console.log("decoded token:");
      console.log(decodedToken);
      console.log("itemServiceUrl = " + this.itemServiceUrl);
  
    return this.http.get<Item[]>(this.itemServiceUrl, {headers: headers})
  }  

  createItem(item : Item): Observable<Item> {

    let token = this.authService.getToken();

    const headers= new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', token);

    return this.http.post<Item>(this.itemServiceUrl, item, {headers: headers}).pipe();
  }
}
