<mat-card *ngIf="!isConfirm">
    <mat-card-content>
        <form #createAccountForm="ngForm" (ngSubmit)="onSignup()">
        <h2>Create Account</h2>
        <mat-form-field>
            <input matInput placeholder="Email" [(ngModel)]="email" name="email" required>
            <mat-error>
            Please provide a valid email address
            </mat-error>
        </mat-form-field>
        <p>
        <mat-form-field>
            <input matInput type="password" placeholder="Password" [(ngModel)]="password" name="password" required>
            <mat-error>
            Please provide a valid password
            </mat-error>
        </mat-form-field>
        <p>
        <button mat-raised-button color="primary" [disabled]="!createAccountForm.form.valid">Create</button>
        </form>
    </mat-card-content>
</mat-card> 

<mat-card *ngIf="isConfirm">
    <mat-card-content>
        <form #confirmAccountForm="ngForm" (ngSubmit)="confirmSignUp()">
        <h2>Confirm Account</h2>
        <mat-form-field>
            <input matInput placeholder="Code" [(ngModel)]="code" name="code" required>
            <mat-error>
            Please provide code
            </mat-error>
        </mat-form-field>
        <p>
        <button mat-raised-button color="primary" [disabled]="!confirmAccountForm.form.valid">Confirm</button>
        </form>
    </mat-card-content>
</mat-card> 