<mat-toolbar color="primary">
  <button mat-icon-button>
    <mat-icon>menu</mat-icon>
  </button>

  <span><a routerLink="/dashboard">Watched</a></span>

  <span class="menu-spacer"></span>

  <a class="nav-link" routerLink="" *ngIf="isLoggedIn()" (click)="logout()">Sign out</a>
  <a class="nav-link" routerLink="/signup" routerLinkActive="active" *ngIf="!isLoggedIn()">Sign up</a>

  <button mat-icon-button *ngIf="isLoggedIn()">
    <mat-icon>more_vert</mat-icon>
  </button>

  <button mat-icon-button class="example-icon favorite-icon" *ngIf="isLoggedIn()" (click)="goToProfile();">
    <mat-icon>account_circle</mat-icon>
  </button>

</mat-toolbar>

<router-outlet></router-outlet>
