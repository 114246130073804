<mat-card>
    <mat-card-content>
        <form #createItemForm="ngForm" (ngSubmit)="onCreateItem()">
            <h2>Create Item</h2>

            <mat-error *ngIf="!itemValid">
                Something is wrong!!!
            </mat-error>

            <mat-form-field>
                <input matInput placeholder="Title" [(ngModel)]="title" name="title" required>
                <mat-error>
                Please provide a valid title
                </mat-error>
            </mat-form-field>
            <p>
            <mat-form-field>
                <input matInput placeholder="Provider" [(ngModel)]="provider" name="provider" required>
                <mat-error>
                Please provide a valid provider
                </mat-error>
            </mat-form-field>
            <p>
            <mat-form-field>
                <input matInput placeholder="Device" [(ngModel)]="device" name="device" required>
                <mat-error>
                Please provide a valid device
                </mat-error>
            </mat-form-field>
            <p>
            <mat-form-field>
                <input matInput placeholder="Location" [(ngModel)]="location" name="location" required>
                <mat-error>
                Please provide a valid location
                </mat-error>
            </mat-form-field>
            <p>
            <mat-form-field>
                <input matInput placeholder="Type" [(ngModel)]="type" name="type" required>
                <mat-error>
                Please provide a valid type
                </mat-error>
            </mat-form-field>
            <p>

            <button mat-raised-button style="margin: 10px" color="primary" [disabled]="!createItemForm.form.valid">Create</button>            
            <button mat-raised-button style="margin: 10px" type="button" (click)="onCancel()">Cancel</button>
        </form>
    </mat-card-content>
</mat-card> 