<div class="page-header">
  <button mat-fab color="primary" (click)="goToCreateItem();">
    <mat-icon>add</mat-icon>
  </button>
</div>

<h1>Dashboard</h1>

<div class="items-container">

        <mat-card class="item-card" *ngFor="let item of items">
            <mat-card-header>
              <mat-card-title>{{item.title}}</mat-card-title>
              <mat-card-subtitle>{{item.provider}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-actions align="end">
              <button mat-button (click)="onViewItem(item)">View</button>
            </mat-card-actions>
        </mat-card>

</div>
