import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

import { Item } from '../../item';
import { ItemService } from '../../item.service';

@Component({
  selector: 'app-create',
  templateUrl: './createitem.component.html',
  styleUrls: ['./createitem.component.scss']
})
export class CreateItemComponent {
  title: string = "";
  provider: string = "";
  device: string = "";
  location: string = "";
  type: string = "";
  itemValid:boolean = true;

  constructor(private router: Router,
              private itemService: ItemService) { }

  onCreateItem() {

    let newDate: Date = new Date();
    const datepipe: DatePipe = new DatePipe('en-US')
    let formattedDate = datepipe.transform(newDate, 'YYYY-MM-ddTHH:mm:ss')
    if (!formattedDate) {
      formattedDate = "1971-01-01T00:00:00"
    }

    var item:Item = {userid: "paalth",
                     timestamp: formattedDate,
                     title: this.title,
                     provider: this.provider,
                     location: this.location,
                     device: this.device,
                     type: this.type};
    this.itemService.createItem(item)
      .subscribe(item => {
        console.log("Create item completed");
        this.router.navigate(['/dashboard']);
      });
  }

  onCancel(): void {
    this.router.navigate(['/dashboard']);
  }
}
